// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import admins from '@src/views/career/admins/store/reducer'
import vacancies from '@src/views/career/vacancies/store/reducer'
import applications from '@src/views/career/applications/store/reducer'
import candidates from '@src/views/career/candidates/store/reducer'
import documents from '@src/views/career/documents/store/reducer'
import vacancyApplications from "@src/views/career/vacancies/store/reducer/vacancy-applications"

const rootReducer = {
	auth,
	admins,
	vacancies,
	vacancyApplications,
	applications,
	candidates,
	documents,
	todo,
	chat,
	email,
	users,
	navbar,
	layout,
	invoice,
	calendar,
	ecommerce,
	dataTables,
	permissions,
}

export default rootReducer
